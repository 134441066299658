<template>
  <div>
    <canvas
      class="video"
      ref="video"
      @click="$emit('click', { viewId, cameraid })"
    ></canvas>
  </div>
</template>

<script>
import Parser from "@/Utils/x-mixed-replace-parser.js";
export default {
  name: "CameraView",
  props: {
    viewId: {
      type: Number,
      required: true,
    },
    cameraid: {
      type: Number,
      default: () => 0,
    },
    height: {
      type: Number,
      default: () => 100,
    },
    stop: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    canvas: null,
    context: null,
    abortController: null,
    parser: null,
    resolucao: {
      width: 0,
      height: 0,
    },
  }),
  methods: {
    nocam() {
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.context.fillStyle = "rgba(199, 199, 199, 1)";
      this.context.font = "15px Arial";
      this.context.fillText(
        "Selecione uma câmera",
        this.canvas.width / 2 - 80,
        this.canvas.height / 2
      );
    },
    async startViewer(cameraId) {
      this.abortController = new AbortController();
      console.log("startViewer", cameraId);
      // pegando a url padrão
      await fetch(
        `${this.$http.defaults.baseURL}realTime/${cameraId}?onlySnapshot=true`,
        {
          method: "GET",
          // formato para receber o arraybuffer
          responseType: "arraybuffer",
          // abort controller para cancelar a requisição
          signal: this.abortController.signal,
          headers: {
            // pegando a autorização do axios
            Authorization: this.$http.defaults.headers.common["Authorization"],
          },
        }
      )
        .then(async (response) => {
          // pega o boundary
          const boundary = response.headers
            .get("Content-Type")
            .split("boundary=")[1];
          this.parser.setBoundary(boundary);
          console.log("boundary", boundary);
          // inicia o reader
          const reader = response.body.getReader();
          // fica no loop até terminar o reader ou o abort for chamado
          let run = true;
          do {
            const { done, value } = await reader.read();
            // caso o reader tenha terminado ou o abort for chamado, sai do loop
            if (done) run = false;
            if (this.abortController.signal.aborted) run = false;
            // caso ainda esteja rodando, adiciona o chunk no parser
            if (run) {
              this.parser.addChunk(Buffer.from(value, "binary"));
            }
          } while (run);
        })
        .catch((error) => {
          this.abortController.abort();
          this.abortController = new AbortController();
          console.log("Erro ou abort", error);
        });
    },
    updatePic(boundary) {
      // cria uma nova imagem
      let image = new Image();
      image.onload = () => {
        this.resolucao.width = image.width;
        this.resolucao.height = image.height;
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.context.drawImage(
          image,
          0,
          0,
          this.canvas.width,
          this.canvas.height
        );
      };
      // gera o base64 da imagem, baseado no boundary
      this.img =
        "data:" +
        boundary.contentType +
        ";base64," +
        Buffer.from(boundary.data).toString("base64");
      // injeto a imagem no canvas
      image.src = this.img;
    },
    stopViewer() {
      console.log("stopViewer");
      this.abortController.abort();
      // this.abortController = new AbortController();
    },
  },
  watch: {
    cameraid: {
      handler(val) {
        if (val == 0) {
          this.nocam();
          this.abortController.abort();
  
        } else {
          this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
          console.log("tem camera", val);
          this.startViewer(val);
        }
      },
      deep: true,
    },
    stop: {
      handler(val) {
        if (val) {
          this.stopViewer();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.canvas = this.$refs.video;
    this.context = this.canvas.getContext("2d");
    this.cameraid == 0
      ? this.nocam()
      : console.log("tem camera", this.cameraid);

    if (!this.parser) {
      this.parser = new Parser();
      // escuta o evento de boundary
      this.parser.on("boundary", (boundary) => {
        if (boundary.contentType == "image/jpeg") {
          this.updatePic(boundary);
        } else if (boundary.contentType == "text/plain") {
          // se vier um texto, é porque foi detectado movimento
          let incomming = boundary.data.toString();
          let text = "";
          text =
            incomming == "motion"
              ? "Movimento Detectado " + this.cameraid
              : text;
          text =
            incomming == "started" ? "Video inciado " + this.cameraid : text;
          text = incomming == "error" ? "Erro na camera " + this.cameraid : text;
          this.$store.dispatch("resetSnack");
          this.$store.dispatch("showSnack", text);
        }
      });
      // this.abortController = new AbortController();
    }
  },
  // beforeRouteLeave(to, from, next) {
  //   console.log("beforeRouteLeave", to, from);
  //   this.abortController.abort();
  //   next();
  // },
  beforeDestroy() {
    console.log("beforeDestroy");
    this.abortController.abort();
  },
};
</script>

<style>
.video {
  border: 1px solid;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
</style>