<template>
  <v-dialog v-model="value" persistent max-width="350">
    <v-card>
      <v-card-title class="text-h5"> Selecione uma Câmera </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-autocomplete
              :items="$store.getters.getCamerasSelect"
              label="Selecione uma Câmera"
              v-model="camera"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="removeCard(false)">
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "RemoveCard",
  props: ["value", "cameraId", "viewId"],
  data: () => ({
    camera: 0,
  }),
  methods: {
    removeCard() {
      this.camera = 0;
      this.$emit("input", false);
    },
    selected(cameraId) {
      if (cameraId) {
        this.$emit("add", { cameraId: this.camera, viewId: this.viewId });
        this.$emit("input", false);
        this.camera = 0;
      }
    },
  },
  watch: {
    camera: function (val) {
      if (val){
        this.selected(val);
      }
    },
  },
};
</script>

<style>
</style>