<template>
  <v-card outlined :height="height">
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <CameraView
            :height="height / 2"
            :stop="stopCameras"
            :cameraid="camerasId[0]"
            @click="onclickView"
            :viewId="0"
          />
        </v-col>
        <v-col cols="6">
          <CameraView
            :height="height / 2"
            :stop="stopCameras"
            :cameraid="camerasId[1]"
            @click="onclickView"
            :viewId="1"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <CameraView
            :height="height / 2"
            :stop="stopCameras"
            :cameraid="camerasId[2]"
            @click="onclickView"
            :viewId="2"
          />
        </v-col>
        <v-col cols="6">
          <CameraView
            :height="height / 2"
            :stop="stopCameras"
            :cameraid="camerasId[3]"
            @click="onclickView"
            :viewId="3"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <RemoveCard
      v-model="removeCardData.value"
      :cameraId="removeCardData.cameraId"
      :viewId="removeCardData.viewId"
      @remove="removeCam"
    />
    <AddCard
      v-model="addCamData.value"
      :cameraId="addCamData.cameraId"
      :viewId="addCamData.viewId"
      @add="addCam"
    />
  </v-card>
</template>

<script>
import CameraView from "./components/CameraView.vue";
import RemoveCard from "./components/RemoveCard";
import AddCard from "./components/AddCard.vue";
import { mapGetters } from "vuex";
export default {
  name: "Visualizacao",
  components: {
    CameraView,
    RemoveCard,
    AddCard,
  },
  computed: {
    ...mapGetters(["getWindowSize"]),
  },
  data: () => ({
    height: 0,
    camerasId: [0, 0, 0, 0],
    removeCardData: {
      value: false,
      cameraId: 0,
      viewId: 0,
    },
    addCamData: {
      value: false,
      cameraId: 0,
      viewId: 0,
    },
    stopCameras: false,
  }),
  methods: {
    onclickView(data) {
      console.log(data);
      if (data.cameraid != 0) {
        this.removeCardData.value = true;
        this.removeCardData.id = data.cameraid;
        this.removeCardData.viewId = data.viewId;
      } else {
        this.addCamData.value = true;
        this.addCamData.id = data.cameraid;
        this.addCamData.viewId = data.viewId;
      }
    },
    removeCam(data) {
      if (typeof data.viewId == "number") {
        this.camerasId[data.viewId] = 0;
      }
      this.updateLocalStorage();
    },
    addCam(data) {
      if (data.cameraId) {
        this.camerasId[data.viewId] = data.cameraId;
      }
      this.updateLocalStorage();
    },
    updateLocalStorage() {
      localStorage.setItem("camerasIdRealTime", JSON.stringify(this.camerasId));
    },
  },
  mounted() {
    this.height = this.getWindowSize.height * 0.86;
    this.$store.dispatch("loadCameras");
    this.stopCameras = false;
    setTimeout(() => {
      this.camerasId = JSON.parse(
        localStorage.getItem("camerasIdRealTime")
      ) || [0, 0, 0, 0];
    }, 500);
  },
  beforeRouteLeave(to, from, next) {
    this.stopCameras = true;
    this.camerasId = [0, 0, 0, 0];
    next();
  },
};
</script>

<style>
</style>