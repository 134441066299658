<template>
  <v-dialog v-model="value" persistent max-width="290">
    <v-card>
      <v-card-title class="text-h5">
       Remover Camera?
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="removeCard(false)">
          Não
        </v-btn>
        <v-btn color="green darken-1" text @click="removeCard(true)">
          Sim
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "RemoveCard",
  props: ["value", "cameraId", "viewId"],
  methods: {
    removeCard(confirm) {
      this.$emit("input", false);
      if (confirm) {
        this.$emit("remove", { cameraId: this.cameraId, viewId: this.viewId});
      }
    },
  },
};
</script>

<style>
</style>